import React, { useState, useEffect } from "react";
import TopHeader from "../components/TopHeader";
import "./../App.css";
import logo from "./../logo.png";
import displayToast from "./../functions/displayToast";
import API from "./../api/api";
import "bootstrap/dist/css/bootstrap.min.css";

const SalesChannelNew = () => {
  let [name, setName] = useState("");
  let [accessToken, setAccessToken] = useState("");
  let [message, setMessage] = useState("");

  let handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let res = await API("POST", `/salesChannel/new`, {
        name: name,
        accessToken: accessToken,
      });
      console.log(`res: ${JSON.stringify(res)}`);
      if (res.success === true) {
        setName("");
        setAccessToken("");
        setMessage(res.message);
        displayToast("success", res.message);
      } else {
        setMessage(res.message);
        displayToast("error", res.message);
      }
    } catch (err) {
      console.log(err);
      setMessage(err.message);
      displayToast("warning", err.message);
    }
  };

  return (
    <div className="App">
      <header className="App-header">
        <link
          rel="stylesheet"
          href="https://use.fontawesome.com/releases/v5.7.2/css/all.css"
        ></link>
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-6 offset-sm-3 d-flex justify-content-center logogroup">
              <img className="logo-component" src={logo} width="60px"></img>
              <p className="logoname logo-component">T05 PAY</p>
            </div>
          </div>
        </div>
      </header>
      <div className="bg-sales-channel">
        <div className="container">
          <TopHeader
            pageTitle="New Sales Channel"
            createBtn
            btnText="Back"
            createBtnLink={"/salesChannel/list"}
          />
          <div className="row m-0">
            <div className="col-12 col-sm-6 offset-sm-3">
              <div className="row">
                <div className="col-12 mb-4">
                  <div className="row box-right-payment">
                    <div className="ps-0 ">
                      <form onSubmit={handleSubmit}>
                        <div className="d-flex pb-2 pt-3">
                          <p className="fw-bold h6"> Name</p>
                        </div>
                        <div className="row">
                          <div className="col-12 mb-2">
                            <input
                              className="form-control"
                              id="name-field"
                              name="name"
                              type="text"
                              value={name}
                              required
                              placeholder="John Doe"
                              onChange={(e) => setName(e.target.value)}
                            ></input>
                          </div>
                        </div>
                        <div className="d-flex pb-2 pt-3">
                          <p className="fw-bold h6"> Access Token</p>
                        </div>
                        <div className="row">
                          <div className="col-12 mb-2">
                            <input
                              className="form-control"
                              id="access-token-field"
                              name="accessToken"
                              type="password"
                              value={accessToken}
                              required
                              onChange={(e) => setAccessToken(e.target.value)}
                            ></input>
                          </div>
                        </div>
                        <input
                          className="btn btn-primary d-block h8 col-12"
                          type="submit"
                          value="ADD"
                        />
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SalesChannelNew;

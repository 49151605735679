import React, { useEffect, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./../App.css";
import "./custom.css";
import gif12 from "../gif/JackTheParrot-128px-12.gif";
import { useSearchParams, useLocation, useNavigate } from "react-router-dom";

let savedParams;
let formattedDate;
let check;
let refNo;
let createdAt;
let queryParams;

const Failed = () => {
  let countRef = useRef(0);
  let [searchParams, setSearchParams] = useSearchParams();

  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    queryParams = new URLSearchParams(location.search);

    if (countRef.current === 0) {
      savedParams = Object.fromEntries(queryParams.entries());
    }
    countRef.current++;

    // Delete all the query parameters
    for (const key of queryParams.keys()) {
      queryParams.delete(key);
    }

    navigate({ search: queryParams.toString() });

    refNo = savedParams.paymentGatewayTransactionId;

    createdAt = savedParams.createdAt;
  }, [location.search, setSearchParams]);
  useEffect(() => {
    function formatDate(dateStr) {
      if (dateStr === null) {
        return;
      }
      let date = new Date(dateStr);
      let day = date.getDate();
      let month = date.getMonth() + 1;
      const d = new Date();
      d.setMonth(month - 1);
      month = d.toLocaleString("default", { month: "long" });
      let year = date.getFullYear();
      let time = date.toLocaleString().split(", ")[1];

      let newDate = `${day} ${month} ${year}, ${time}`;
      return newDate;
    }

    function checkTime(createdAt) {
      let date = new Date(createdAt);
      let time = date.toLocaleString().split(", ")[1];
      let timeSplit = time.split(" ")[0].split(":");
      let timeSeconds =
        timeSplit[0] * 3600 + timeSplit[1] * 60 + timeSplit[2] * 1;
      let timeNow = new Date().toLocaleString().split(", ")[1];
      let timeNowSplit = timeNow.split(" ")[0].split(":");
      let timeNowSeconds =
        timeNowSplit[0] * 3600 + timeNowSplit[1] * 60 + timeNowSplit[2] * 1;
      return timeNowSeconds - timeSeconds;
    }

    formattedDate = formatDate(searchParams.get("createdAt"));
    check = checkTime(searchParams.get("createdAt"));
    // //check if the transaction has passed 60s or invalid transactions
    if (check > 3000) {
      window.location.href = "https://t05pay.sg/";
    }
  }, [navigate]);
  return (
    <div className="App">
      <div>
        <body className="bg-failed">
          <div className="container" style={{ display: "block" }}>
            <div className="row"></div>
            <div className="row">
              <div className="col-12 col-sm-6 offset-sm-3">
                <div className="box-right">
                  <div className="col-12 d-flex gif-logo">
                    <img
                      src={gif12}
                      alt="gif-12"
                      className="failed-logo img"
                    ></img>
                  </div>
                  <p className="failed-status col-12 d-flex center">
                    {" "}
                    Payment Failed
                  </p>
                  <p
                    className="failed-createdAt col-12 center"
                    style={{ wordBreak: "break-word" }}
                  >
                    <p>{formattedDate}</p>
                  </p>
                  <p
                    className="transactionId col-12"
                    style={{ wordBreak: "break-word" }}
                  >
                    <p>Ref: {refNo}</p>
                  </p>
                  <p className="failed-text col-12 d-flex center">
                    Your payment was not processed successfully. Please try
                    again.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </body>
      </div>
    </div>
  );
};

export default Failed;

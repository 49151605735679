import React from "react";
import PageTitle from "./Typography/PageTitle";
import { BackIcon } from "../icons";
import { Button } from "@windmill/react-ui";
import { Link } from "react-router-dom";

const TopHeader = (props) => {
  return (
    <>
      <div className="md:flex items-center my-5">
        <div className="flex items-center mr-5">
          {props.backLink && (
            <Button
              size="small"
              icon={BackIcon}
              layout="link"
              tag={Link}
              to={props.backLink}
            />
          )}
          <PageTitle>{props.pageTitle}</PageTitle>
        </div>

        <div className="mt-3 md:mt-0">
          {props.createBtnLink && (
            <Button tag={Link} to={props.createBtnLink}>
              {props.btnText || "Create New"}
            </Button>
          )}

          {props.onClickCreate && (
            <Button onClick={props.onClickCreate}>
              {props.btnText || "Create New"}
            </Button>
          )}
        </div>
      </div>
    </>
  );
};

export default TopHeader;

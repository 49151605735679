import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Failed from "./pages/failed";
import Payment from "./pages/payment";
import Success from "./pages/success";
import SalesChannelNew from "./pages/salesChannelNew";
import SalesChannelList from "./pages/salesChannelList";

import {
  QueryClient,
  QueryClientProvider,
  useQuery,
} from "@tanstack/react-query";
import { Routes, Route, Navigate } from "react-router-dom";
const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Routes>
        <Route path="failed" element={<Failed />} />
        <Route path="payment" element={<Payment />} />
        <Route path="success" element={<Success />} />
        <Route path="salesChannel/new" element={<SalesChannelNew />} />
        <Route path="salesChannel/list" element={<SalesChannelList />} />
      </Routes>
    </QueryClientProvider>
  );
}

export default App;

import axios from "axios";
// eslint-disable-next-line no-unused-vars
// const { REACT_APP_API_URL } = process.env;

// const endpoint = " https://api.nebula.payrallel.io/";
// const endpoint = "https://staging.api.snp.t05pay.sg";
// const endpoint = REACT_APP_API_URL;
const endpoint = "https://api.snp.t05pay.sg";
const instance = axios.create({ baseURL: endpoint });

const API = async (method, url, data, options) => {
  return instance({
    method,
    url,
    data,
    withCredentials: true,
    ...options,
  }).then(({ data }) => data);
};

export default API;

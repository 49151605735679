import React, { useState, useEffect } from "react";
import { useQuery, useMutation } from "@tanstack/react-query";
import TopHeader from "../components/TopHeader";
import "./../App.css";
import logo from "./../logo.png";
import API from "./../api/api";
import "bootstrap/dist/css/bootstrap.min.css";
import DataTable from "react-data-table-component";

const SalesChannelList = () => {
  const getSalesChannelList = async () => {
    const data = await API("GET", "/salesChannel/list");
    return data;
  };
  const { data, isLoading } = useQuery(["salesChannel"], getSalesChannelList);
  return (
    <div className="App">
      <header className="App-header">
        <link
          rel="stylesheet"
          href="https://use.fontawesome.com/releases/v5.7.2/css/all.css"
        ></link>
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-6 offset-sm-3 d-flex justify-content-center logogroup">
              <img className="logo-component" src={logo} width="60px"></img>
              <p className="logoname logo-component">T05 PAY</p>
            </div>
          </div>
        </div>
      </header>
      <div className="bg-sales-channel">
        <div className="container">
          <TopHeader
            pageTitle="Sales Channel List"
            createBtn
            btnText="Add New Sales Channel"
            createBtnLink={"/salesChannel/new"}
          />
          <div className="row m-0">
            <div className="col-12 col-sm-6 offset-sm-3">
              <div className="row">
                <div className="col-12 mb-4">
                  <div className="row box-sales-channel-list">
                    <div className="ps-0 ">
                      <DataTable
                        columns={[
                          {
                            name: "Name",
                            selector: (row) => (
                              <a href={`${row?.qr}`} target="_blank">
                                {row?.name}
                              </a>
                            ),
                          },
                        ]}
                        data={data ? data?.salesChannelList : []}
                        pagination
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SalesChannelList;

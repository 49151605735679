import React, { useState, useEffect, useRef } from "react";
import { useSearchParams, useLocation, useNavigate } from "react-router-dom";
import gif3 from "../gif/JackTheParrot-128px-3.gif";
import "bootstrap/dist/css/bootstrap.min.css";
import "./custom.css";

let savedParams;
let formattedDate;
let amount;
let refNo;
let check;
let queryParams;

const Success = () => {
  let countRef = useRef(0);
  let [searchParams, setSearchParams] = useSearchParams();

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    queryParams = new URLSearchParams(location.search);

    if (countRef.current === 0) {
      savedParams = Object.fromEntries(queryParams.entries());
    }
    countRef.current++;

    // Delete all the query parameters
    for (const key of queryParams.keys()) {
      queryParams.delete(key);
    }

    navigate({ search: queryParams.toString() });

    amount = savedParams.amountInCents;
    amount = (Number(amount) / 100).toFixed(2);

    refNo = savedParams.paymentGatewayTransactionId;
  }, [location.search, setSearchParams]);

  useEffect(() => {
    function formatDate(dateStr) {
      if (dateStr === null) {
        window.location.href = "https://t05pay.sg/";
      }
      let date = new Date(dateStr);
      let day = date.getDate();
      let month = date.getMonth() + 1;
      const d = new Date();
      d.setMonth(month - 1);
      month = d.toLocaleString("default", { month: "long" });
      let year = date.getFullYear();
      let time = date.toLocaleString().split(", ")[1];

      let newDate = `${day} ${month} ${year}, ${time}`;
      return newDate;
    }

    function checkTime(createdAt) {
      let date = new Date(createdAt);
      let time = date.toLocaleString().split(", ")[1];
      let timeSplit = time.split(" ")[0].split(":");
      let timeSeconds =
        timeSplit[0] * 3600 + timeSplit[1] * 60 + timeSplit[2] * 1;
      let timeNow = new Date().toLocaleString().split(", ")[1];
      let timeNowSplit = timeNow.split(" ")[0].split(":");
      let timeNowSeconds =
        timeNowSplit[0] * 3600 + timeNowSplit[1] * 60 + timeNowSplit[2] * 1;
      return timeNowSeconds - timeSeconds;
    }

    formattedDate = formatDate(searchParams.get("createdAt"));
    check = checkTime(searchParams.get("createdAt"));

    // //check if the transaction has passed 60s or invalid transactions
    if (check > 3000 || check < 0) {
      window.location.href = "https://t05pay.sg/";
    }

    const handleRefresh = (e) => {
      if (!e) e = window.event;
      if (e.preventDefault) e.preventDefault();
      navigate("https://t05pay.sg/");
    };

    window.addEventListener("beforeunload", handleRefresh);
    return () => {
      window.removeEventListener("beforeunload", handleRefresh);
    };
  }, [navigate]);

  return (
    <div className="App">
      <div>
        <body className="bg-success">
          <div className="container" style={{ display: "block" }}>
            <div className="row "></div>
            <div className="row">
              <div className="col-12 col-sm-6 offset-sm-3">
                <div className="box-right">
                  <div className="col-12 d-flex gif-logo">
                    <img
                      src={gif3}
                      alt="gif-3"
                      className="success-logo img"
                    ></img>
                  </div>
                  <p className="success-status col-12 d-flex center">
                    Payment Successful
                  </p>
                  <p
                    className="success-createdAt col-12 center"
                    style={{ wordBreak: "break-word" }}
                  >
                    <p>{formattedDate}</p>
                  </p>
                  <p
                    className="transactionId col-12"
                    style={{ wordBreak: "break-word" }}
                  >
                    <p> Ref: {refNo}</p>
                  </p>
                  <p
                    className="success-amount col-12"
                    style={{ wordBreak: "break-word" }}
                  >
                    <p>You have successfully paid</p>
                  </p>
                  <p
                    className="success-amount-text col-12 d-flex justify-content-center align-items-start"
                    style={{ wordBreak: "break-word" }}
                  >
                    <p>${amount}</p>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </body>
      </div>
    </div>
  );
};

export default Success;
